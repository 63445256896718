<template>
  <div>
    <!-- <pre>{{ pdetails }}</pre> -->
    <v-row class="px-4 purchase-order-row">
      <v-col md="12" class="px-0 py-0">
        <div class="px-5 py-2 blue lighten-4" v-if="false">
          <table width="100%">
            <tr>
              <th width="25%">Description</th>
              <th width="25%">Due Date</th>
              <th width="25%">Item</th>
              <th width="25%">Original Amount</th>
              <!-- <th width="12%">Open Balance</th>
              <th width="15%">Payment</th> -->
            </tr>
          </table>
        </div>

        <v-expansion-panels multiple elevation="1" v-model="panel" class="altclass">
          <v-expansion-panel class="mt-0">
            <v-expansion-panel-header v-if="false">
              <!-- <h5 class="my-0 px-0 py-0">Line Items</h5> -->
              <table width="100%">
                <tr>
                  <td width="25%">
                    <div class="d-flex align-center">
                      <!-- <v-checkbox
                        class="mx-0 px-0 my-0"
                        hide-details
                        v-model="row.billSelectCheck"
                        :value="item.billSelectCheck"
                      ></v-checkbox> -->
                      <div>
                        <span class="blue--text">{{ pdetails.barcode }}</span>
                        <v-chip small outlined>{{ pdetails.delivery_date }}</v-chip>
                      </div>
                    </div>
                  </td>
                  <td width="26%">
                    <v-chip small :color="getDueDate(pdetails) > 0 ? 'red' : ''" outlined>
                      {{ pdetails.due_date }}
                    </v-chip>
                  </td>
                  <td width="26%">
                    <v-badge class="ms-3" color="blue" :content="pdetails.order_items?.length">
                    </v-badge>
                  </td>

                  <td width="23%">
                    <div class="ms-3">{{ pdetails.amount }}</div>
                  </td>
                  <!-- <td width="12%"><div class="ms-4">{{ row.open_balance }}</div></td>
                  <td width="13%" align="center">{{ row.payment }}</td> -->
                </tr>
              </table>
            </v-expansion-panel-header>

            <div class="w-100 po-line-items">
              <table width="100%">
                <thead>
                  <tr>
                    <th class="pa-2" width="40">Image</th>
                    <th class="pa-2" width="200">Product</th>
                    <th class="pa-2" width="300">Description</th>
                    <th class="pa-4 text-center" width="500">UOM</th>
                    <th class="pa-2 text-center" width="120">Qty</th>
                    <th class="pa-2 text-center" width="120"></th>
                    <th class="pa-2" width="200">Rate</th>
                    <th class="pa-2 text-right" width="200">Amount</th>
                    <!-- <th class="pa-2 text-right" width="120">Pending</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in pdetails.order_items" :key="index.id">
                    <td class="px-2" align="center">
                      <div>
                        {{ row.transaction_documents?.file?.url }}
                        <v-avatar size="35px">
                          <img
                            v-if="row?.product?.transaction_documents?.file?.url"
                            max-height="40"
                            max-width="40"
                            :src="row?.product?.transaction_documents?.file?.url"
                            alt="John"
                          />
                          <img
                            v-else
                            max-height="40"
                            max-width="40"
                            src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/OFVhotIVFLGfIcidqwYD9YQxR3edOtSq7cZaTji6.png"
                            alt="John"
                          />
                        </v-avatar>
                      </div>
                    </td>

                    <td class="px-2">
                      <div>{{ row.product?.name_en }} ({{ row.product?.barcode }})</div>
                    </td>
                    <td class="px-2">
                      <div>{{ row.description }}</div>
                    </td>
                    <td class="px-2" align="center">
                      <div class="d-flex justify-center">
                        <span>{{ row.uom }}</span>

                        <!-- {{ row.product.primary_sales_uom }}
                        {{ row.product.secondary_purchase_uom }} -->

                        <v-badge
                          v-if="row.uom == row?.product?.primary_sales_uom"
                          :color="row.billType == 'P' ? 'green' : 'blue'"
                          inline
                        ></v-badge>
                        <v-badge
                          v-else
                          :color="row.billType == 'P' ? 'green' : 'red'"
                          inline
                        ></v-badge>
                      </div>
                    </td>
                    <td class="px-2" align="center">
                      <div>{{ row.quantity }}</div>
                    </td>
                    <td class="px-2" align="center">
                      <div></div>
                    </td>
                    <td class="px-2">
                      <div>{{ formatMoney(row.rate) }}</div>
                    </td>
                    <!-- <td></td> -->
                    <td class="px-2" align="right">
                      <div>{{ formatMoney(row.quantity * row.rate) }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="pl-5 pr-2 py-3">
          <table width="100%">
            <tfoot>
              <tr>
                <td class="py-1 borer-none text-right" colspan="8"><b>Sub Total</b></td>
                <td
                  v-if="pdetails && pdetails.sub_total"
                  class="py-1 borer-none text-right"
                  width="180"
                >
                  {{ formatMoney(pdetails.sub_total) }}
                </td>
                <td v-else class="py-1 borer-none text-right" width="180">0.00</td>
              </tr>
              <tr>
                <td class="py-1 borer-none text-right" colspan="8">
                  <div class="d-flex align-items-center justify-content-end">
                    <b>Discount </b>
                    <div style="max-width: 80px" class=""></div>
                    <div style="max-width: 80px" class=""></div>
                  </div>
                </td>
                <td
                  v-if="pdetails && pdetails.total_discount"
                  class="py-1 borer-none text-right"
                  width="180"
                >
                  {{ formatMoney(pdetails.total_discount) }}
                </td>
                <td v-else class="py-1 borer-none text-right" width="180">RM0.00</td>
              </tr>

              <tr v-if="pdetails.tax_applied">
                <td class="py-1 borer-none text-right" colspan="8">
                  <div class="d-flex align-items-center justify-content-end">
                    <b>Tax 9</b>
                    <div style="max-width: 60px" class="ml-2">
                      <span class="font-weight-bold" style="font-size: 16px"> % </span>
                    </div>
                  </div>
                </td>
                <td
                  v-if="pdetails && pdetails.tax_amount"
                  class="py-1 borer-none text-right"
                  width="180"
                >
                  {{ formatMoney(pdetails?.tax_amount) }}
                </td>

                <td v-else class="py-1 borer-none text-right" width="180">RM0.000</td>
              </tr>
              <tr>
                <td class="py-1 borer-none text-right" colspan="8">
                  <div class="d-flex align-items-center justify-content-end">
                    <b>Adjustment</b>
                    <div style="max-width: 60px" class="ml-0"></div>
                  </div>
                </td>
                <td
                  v-if="pdetails && pdetails.adjustment"
                  class="py-1 borer-none text-right"
                  width="180"
                >
                  {{ formatMoney(pdetails?.adjustment) }}
                </td>
                <td v-else class="py-1 borer-none text-right" width="180">RM0.000</td>
              </tr>
              <tr>
                <td class="py-1 borer-none text-right green--text" colspan="8">
                  <b style="font-size: 20px">Grand Total</b>
                </td>
                <td class="py-1 borer-none text-right green--text" width="180">
                  <b v-if="pdetails && pdetails.total" style="font-size: 20px">
                    {{ formatMoney(pdetails.total) }}</b
                  >
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MomentJS from "moment-timezone";
import { formatMoney } from "accounting-js";

export default {
  name: "po-line-items",
  props: {
    pdetails: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      panel: [0],
      product: [],
      tax_amountss: 9,
      billListData: [
        // {
        //   id: 1,
        //   po_number: "PO0003",
        //   bill_date: "12/09/2023",
        //   due_date: "12/09/2023",
        //   amount: "4,750",
        //   open_balance: "0.00",
        //   payment: "Payment",
        //   billSelectCheck: true,
        // },
        // {
        //   id: 2,
        //   po_number: "PO0002",
        //   bill_date: "12/09/2023",
        //   due_date: "12/09/2023",
        //   amount: "4,750",
        //   open_balance: "0.00",
        //   payment: "Payment",
        //   billSelectCheck: false,
        // },
        // {
        //   id: 3,
        //   po_number: "PO0003",
        //   bill_date: "11/09/2023",
        //   due_date: "12/09/2023",
        //   amount: "4,750",
        //   open_balance: "0.00",
        //   payment: "Payment",
        //   billSelectCheck: false,
        // },
        // {
        //   id: 4,
        //   po_number: "PO0004",
        //   bill_date: "12/09/2023",
        //   due_date: "12/09/2023",
        //   amount: "4,750",
        //   open_balance: "0.00",
        //   payment: "Payment",
        //   billSelectCheck: false,
        // },
        // {
        //   id: 5,
        //   po_number: "PO0005",
        //   bill_date: "12/09/2023",
        //   due_date: "12/09/2023",
        //   amount: "4,750",
        //   open_balance: "0.00",
        //   payment: "Payment",
        //   billSelectCheck: false,
        // },
      ],
      line_items: [
        {
          id: 1,
          product: "aircon",
          description: "Description",
          quantity: 1,
          order: 1,
          uom: "pcs",
          rate: "$4,000.00",
          total: "$500.00",
          billType: "P",
        },
        {
          id: 2,
          product: "iphone",
          description: "Description",
          quantity: 1,
          order: 1,
          uom: "pcs",
          rate: "$500.00	",
          total: "$16,000.00",
          billType: "S",
        },
        {
          id: 3,
          product: "iphone",
          description: "Description",
          quantity: 1,
          order: 1,
          uom: "pcs",
          rate: "$4,000.00",
          total: "$16,000.00",
          billType: "P",
        },
        {
          id: 4,
          product: "iphone",
          description: "Description",
          quantity: 1,
          order: 1,
          uom: "pcs",
          rate: "$4,000.00",
          total: "$16,000.00",
          billType: "S",
        },
        {
          id: 5,
          product: "iphone",
          description: "Description",
          quantity: 1,
          order: 1,
          uom: "pcs",
          rate: "$4,000.00",
          total: "$16,000.00",
          billType: "P",
        },
      ],
    };
  },
  methods: {
    getTax() {
      if (this.pdetails.tax_applied) {
        const _taxable_amount =
          Number(this.pdetails.sub_total) - Number(this.pdetails.total_discount);
        console.log(_taxable_amount, "_taxable_amount");
        this.tax_amount = Number((_taxable_amount * this.tax_amountss) / 100);
        this.taxable_amount = _taxable_amount;
      } else {
        this.tax_amount = 0;
      }
      return this.tax_amount;
    },
    // getDiscount(){
    //       this.product = this.pdetails

    // 		let sum = 0;
    //     console.log(this.pdetails)
    // 		this.product?.forEach((element) => {

    // 			sum = sum + Number(element.total_discount);
    // 		});
    //     console.log(sum)
    // 		this.discount_total = Number(sum);
    // 		//  this.getDiscount();
    // 		return this.discount_total;

    //     },
    // getSubtotal(){
    //   this.product = this.pdetails

    // let sum = 0;
    // console.log(this.pdetails)
    // this.product?.forEach((element) => {

    // 	sum = sum + Number(element.sub_total);
    // });
    // console.log(sum)
    // this.sub_total = Number(sum);
    // //  this.getDiscount();
    // return this.sub_total;

    // },
    // getTax(){
    //   this.product = this.pdetails

    // let sum = 0;
    // console.log(this.pdetails)
    // this.product?.forEach((element) => {

    // 	sum = sum + Number(element.tax_amount);
    // });
    // console.log(sum)
    // this.tax= Number(sum);
    // //  this.getDiscount();
    // return this.tax;

    // // },
    // getTotal(){
    //   this.product = this.pdetails

    // let sum = 0;
    // console.log(this.pdetails)
    // this.product?.forEach((element) => {

    // 	sum = sum + Number(element.total);
    // });
    // console.log(sum)
    // this.total= Number(sum);
    // //  this.getDiscount();
    // return this.total;

    // },
    // getAdjustment(){
    //   this.product = this.pdetails

    // let sum = 0;
    // console.log(this.pdetails)
    // this.product?.forEach((element) => {

    // 	sum = sum + Number(element.adjustment);
    // });

    // this.adjustment= Number(sum);
    // //  this.getDiscount();
    // return this.adjustment;

    // },

    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    getDueDate(data) {
      let firstDate = MomentJS(data.due_date);
      let secondDate = MomentJS(data.bill_date);

      let result = firstDate.diff(secondDate, "days");
      return result;
    },
  },
};
</script>

<style scoped>
.th {
  font-size: 13px !important;
}

.purchase-order-row {
  margin: 0 !important;
}
.po-line-items thead {
  /* background-color: #bbdefb !important; */
  background-color: #ede8e8 !important;
}
.po-line-items th {
  font-weight: 500;
}
.po-line-items th,
.po-line-items td:not(.borer-none) {
  border-bottom: 1px solid #c7c7c7;
  /* border-left: 1px solid #c7c7c7; */
  height: 40px;
}
.po-line-items tbody tr:nth-child(even) {
  /* background-color: #e3eff9 !important; */
  background-color: #f9f1f1 !important;
}
.altclass.theme--light.v-expansion-panels
  .v-expansion-panel:nth-child(even)
  .v-expansion-panel-header {
  background-color: #e3eff9 !important;
}
.po-line-items th:first-child,
.po-line-items td:first-child {
  border-left: none !important;
}
.v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
