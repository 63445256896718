<template>
  <div>
    <!-- <pre>{{ parentdetails.record_payment }}</pre> -->
    <v-row class="px-4 product-row">
      <v-col md="12" class="px-0 py-0">
        <v-expansion-panels elevation="1" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5 class="my-0 px-0 py-0">Payment</h5>
            </v-expansion-panel-header>
            <v-divider class="my-0"></v-divider>
            <v-expansion-panel-content class="px-0 py-0">
              <div class="w-100 po-line-items">
                <table width="100%">
                  <thead>
                    <tr>
                      <th class="pa-2" width="25%">
                        <h6 class="blue--text text--darken-4 text-h6">Payment</h6>
                      </th>
                      <th class="pa-2" width="25%">
                        <h6 class="blue--text text--darken-4 text-h6">Payment Date</h6>
                      </th>
                      <th class="pa-2" width="25%">
                        <h6 class="blue--text text--darken-4 text-h6">Payment Mode</h6>
                      </th>
                      <th class="pa-2" width="25%">
                        <h6 class="blue--text text--darken-4 text-h6">Reference</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in parentdetails.record_payment" :key="index.id">
                      <td class="px-2">
                        <div>
                          <span>{{ formatMoney(row.parent_id.payment) }}</span>
                        </div>
                      </td>
                      <td class="px-2">
                        <div>
                          <span>{{ formatedateTimes(row.parent_id.payment_date) }}</span>
                        </div>
                      </td>
                      <td class="px-2">
                        <div>
                          <span>{{ row.parent_id.payment_mode }}</span>
                        </div>
                      </td>
                      <td class="px-2">
                        <div>
                          <span>{{ row.parent_id.reference }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatMoney } from "accounting-js";
import moment from "moment-timezone";

export default {
  name: "payment",
  props: {
    parentdetails: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      panel: [0],
      // product: {
      // }
    };
  },
  methods: {
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
  },
};
</script>

<style scoped>
.th {
  font-size: 13px !important;
}

.product-row {
  margin: 0 !important;
}
</style>
