<template>
  <div style="min-height: calc(100vh - 500px)">
    <v-layout class="p-4 border-bottom-light-grey min-height-57px">
      <v-flex class="font-level-3-bold my-auto">
        <span class="detail-svg-icon mr-2">
          <!--begin::Svg Icon-->
          <inline-svg :src="$assetURL('media/custom-svg/files-color.svg')" />
          <!--end::Svg Icon-->
        </span>
        <template v-if="typeText"> </template>
        <template v-else> Files </template>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          v-on:click="attachDialog = true"
          color="blue darken-4 text-white"
          class="ml-2"
          depressed
          tile
          ><v-icon small left>mdi-playlist-plus</v-icon>Attach File</v-btn
        >
      </v-flex>
    </v-layout>
    <Loading v-if="contentLoading"></Loading>
    <div v-else class="overflow-y" style="max-height: calc(100vh - 345px)">
      <v-simple-table fixed-header height="500px">
        <thead>
          <tr>
            <th width="50" v-if="false"></th>
            <th class="p-2" style="width: 200px">Name</th>
            <th class="p-2" width="200">Date & Time</th>
            <th class="p-2" align="center" style="text-align: center" width="200">Size</th>
            <th class="p-2" align="right" style="text-align: right" width="200">Download</th>
          </tr>
        </thead>
        <tbody v-if="dbFiles.length">
          <tr v-for="(row, index) in dbFiles" :key="index">
            <td class="p-2 border-top-light-grey" width="50" v-if="false">
              <ImageTemplate style="max-width: 50px; width: 50px"></ImageTemplate>
            </td>
            <td class="p-2 border-top-light-grey">
              <div class="d-flex">
                <template v-if="!row.file?.suffix">
                  <ImageTemplate
                    circle
                    style="max-width: 30px; height: 30px"
                    :src="row.file?.url"
                  />
                </template>
                <template v-else>
                  <!-- {{ row.file?.suffix }} -->
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!-- <pre>{{ $assetURL(`media/mime/${row?.file?.suffix}.svg`) }}</pre> -->
                    <inline-svg :src="$assetURL(`media/mime/${row?.file?.suffix}.svg`)" />
                  </span>
                </template>
                <p class="mx-2 blue--text font-level-1">{{ row.name }}</p>
                <Chip
                  v-if="type === 'project-milestone' || type === 'project'"
                  class="ml-2"
                  small
                  :text="row?.module_lable"
                  color="blue darken-4 white--text"
                ></Chip>
              </div>
              <!-- <span class="text-muted font-small"
								>{{ formatDateTime(row.added_at) }}
							</span
							> -->
            </td>
            <td v-if="row.added_at">
              {{ formatDateTime(row.added_at) }}
            </td>

            <td class="p-2 border-top-light-grey font-level-1" align="center" width="200">
              {{ getFileSize(row.file?.size) ? getFileSize(row.file?.size) : 0 }} KB
            </td>
            <td class="p-2 border-top-light-grey font-level-1" align="right" width="200">
              <v-btn
                v-on:click="doAction(row, 'download')"
                small
                icon
                depressed
                color="blue darken-4"
                class="mr-2"
                ><v-icon small>mdi-download</v-icon></v-btn
              >
              <!-- <v-btn small v-on:click="doAction(row, 'delete')" icon depressed color="red lighten-1"
                ><v-icon small>mdi-delete</v-icon></v-btn
              > -->
            </td>
          </tr>
        </tbody>
        <tfoot v-else>
          <tr>
            <td colspan="4">
              <p class="m-0 row-not-found text-center">
                <img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
                Uhh... There are no file at the moment.
              </p>
            </td>
          </tr>
        </tfoot>
      </v-simple-table>
    </div>
    <Dialog :dialog="attachDialog" :dialog-width="768">
      <template v-slot:title> Attach Files</template>
      <template v-slot:body>
        <v-row class="attach-dialog">
          <v-col v-if="attachDialog" md="12">
            <SupplierFile :allow-add-more="allowAddMore()" v-model="nbfiles"></SupplierFile>
            <template v-if="false">
              <p class="font-level-1">Select Files to Upload</p>
              <div class="border-light-grey text-center py-5">
                <v-icon x-large>mdi-cloud-upload-outline</v-icon>
                <br />
                <br />
                <v-btn
                  tile
                  :disabled="attachLoading"
                  class="border-light-grey"
                  v-on:click="selectFile()"
                  text
                  color="blue darken-4"
                  >Choose File</v-btn
                >
                <v-file-input
                  v-on:change="updateFiles($event)"
                  class="d-none"
                  ref="upload-file"
                  multiple
                ></v-file-input>
                <br />
                <br />
                <span>Maximum File Size: {{ maxFileSize() }} MB</span>
                <br />
                <br />
                <template v-for="(row, index) in files">
                  <v-tooltip :key="`tooltip-file-${index}`" top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        :disabled="attachLoading"
                        small
                        close
                        v-on:click:close="removeFile(index)"
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1 mb-2"
                        :key="`chip-file-${index}`"
                        color="blue darken-4 white--text"
                        >{{ row.name }}</v-chip
                      >
                    </template>
                    <span> {{ getFileSize(row.size) }} KB </span>
                  </v-tooltip>
                </template>
              </div>
              <label for="file-description" class="btx-label mt-2">Description</label>
              <TextAreaInput
                :rows="4"
                dense
                id="file-description"
                ref="description"
                hide-details
                v-model="description"
                placeholder="Enter Description..."
                :disabled="attachLoading"
                :loading="attachLoading"
              ></TextAreaInput>
            </template>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn depressed tile :disabled="attachLoading" v-on:click="attachDialog = false">
          Cancel
        </v-btn>
        <v-btn
          class="white--text"
          :loading="attachLoading"
          :disabled="attachLoading"
          depressed
          color="blue darken-4"
          tile
          v-on:click="attachFile()"
        >
          Upload
        </v-btn>
      </template>
    </Dialog>
    <Dialog :dialog="deleteDialog" :dialog-width="600">
      <template v-slot:title> Delete Attachment</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0">
              Deleting Attachment is irreversible, Are you sure about deleting it?
            </p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text"
          :loading="deleteLoading"
          :disabled="deleteLoading"
          depressed
          color="red lighten-1"
          tile
          v-on:click="deleteFile()"
        >
          Yes! Delete
        </v-btn>
        <v-btn depressed tile :disabled="deleteLoading" v-on:click="deleteDialog = false">
          No, Close
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { toNumber, round, find } from "lodash";
import ImageTemplate from "@/view/components/Image";
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import ApiService from "@/core/services/api.service";
// import { EventBus } from "@/core/event-bus/event.bus";
import SupplierFile from "@/view/components/SupplierFile";
import Loading from "@/view/components/Loading";
import Chip from "@/view/components/Chip";
import { SET_ERROR } from "@/core/services/store/common.module";

export default {
  props: {
    typeText: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    typeUuid: {
      type: String,
      default: null,
    },
    cType: {
      type: String,
      default: "0",
    },
    reload: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    reload(param) {
      if (param) {
        this.getFiles();
      }
    },
    attachDialog(param) {
      if (param) {
        this.nbfiles = [];
      }
    },
  },
  data() {
    return {
      pageLoading: false,
      deleteLoading: false,
      contentLoading: false,
      deleteDialog: false,
      attachDialog: false,
      attachLoading: false,
      uuid: null,
      description: null,
      files: [],
      nbfiles: [],
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif", "pdf"],
      dbFiles: [
        /* {
					name: "asdfkagsdui",
					added_at: "03/12/2022",
					created_by: "John Parker",
					size: "129",
				},
				{
					name: "asdfkagsdui",
					added_at: "03/12/2022",
					created_by: "John Parker",
					size: "129",
				},
				{
					name: "asdfkagsdui",
					added_at: "03/12/2022",
					created_by: "John Parker",
					size: "129",
				}, */
      ],
    };
  },
  methods: {
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        console.log(ext);
        return false;
      }
    },
    allowAddMore() {
      if (this.pageLoading) {
        return false;
      }
      const c_type = toNumber(this.cType);
      if (c_type > 0) {
        const total_files = this.nbfiles.length + this.dbFiles.length;
        if (total_files < 5) {
          return true;
        }
        return false;
      }
      return true;
    },
    allowUpload() {
      if (this.pageLoading) {
        return false;
      }
      const c_type = toNumber(this.cType);
      if (c_type > 0) {
        if (this.dbFiles.length < 5) {
          return true;
        }
        return false;
      }
      return true;
    },
    init() {
      this.deleteLoading = false;
      this.deleteDialog = false;
      this.attachDialog = false;
      this.attachLoading = false;
      this.uuid = null;
      this.description = null;
      this.files = [];
      this.nbfiles = [];
    },
    updateFiles(param) {
      if (param) {
        const max_size = toNumber(this.$uploadSizeLIMIT);
        const max_file = toNumber(this.$uploadLIMIT);
        for (let i = 0; i < param.length; i++) {
          const size = round(toNumber(param[i].size / 1024), 2);
          if (size <= max_size && this.files.length < max_file) {
            this.files.push(param[i]);
          }
        }
      }
    },
    getFileSize(size) {
      return round(toNumber(size) / 1024, 2);
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.$refs["upload-file"].value = null;
    },
    maxFileSize() {
      return round(toNumber(this.$uploadSizeLIMIT) / 1024, 2);
    },
    selectFile() {
      this.$refs["upload-file"].reset();
      this.$refs["upload-file"].$refs["input"].value = null;
      this.$nextTick(() => {
        this.$refs["upload-file"].$refs["input"].click();
      });
    },
    doAction(row, param) {
      switch (param) {
        case "download":
          window.open(row.file?.url, "_blank");
          break;
        case "delete":
          this.uuid = row.uuid;
          this.deleteDialog = true;
          break;
      }
    },
    deleteFile() {
      if (!this.uuid) {
        return false;
      }
      this.deleteLoading = true;
      ApiService.setHeader();
      ApiService.patch(`files/${this.type}/${this.typeUuid}/file/${this.uuid}`)
        .then(() => {
          this.getFiles();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    getFiles() {
      this.pageLoading = true;
      this.contentLoading = true;
      ApiService.setHeader();
      ApiService.query(`document/${this.$route.params.id}`, { type_id: this.typeUuid })
        .then(({ data }) => {
          this.dbFiles = data;
          this.init();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
          this.contentLoading = false;
        });
    },
    attachFile() {
      //const params = new FormData();

      // for (let i = 0; i < this.nbfiles.length; i++) {
      // 	params.append(`file[${i}][file]`, this.nbfiles[i].file);
      // 	params.append(`file[${i}][name]`, this.nbfiles[i].name);
      // }

      //params.append("type", toNumber(this.cType));
      // params.append("description", this.description);
      let _payload = this.nbfiles.map((row) => {
        return { ...row, file: null };
      });
      if (_payload.length) {
        _payload = _payload.filter((ele) => {
          if (ele.name) {
            return ele;
          }
        });
      }

      const params = { files: _payload };
      console.log("params", params);
      if (!params.files.length) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "Select at least one files" }]);
        return;
      }
      this.pageLoading = true;
      this.attachLoading = true;
      console.log(this.$route.params.id);
      // this.parent = this.route.params.id;

      ApiService.setHeader();
      ApiService.post(`upload-document/${this.$route.params.id}/${this.typeUuid}`, params)
        .then(() => {
          this.attachDialog = false;
          this.getFiles();
          // this.$refs["upload-file"].value = null;
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.attachLoading = false;
          this.pageLoading = false;
        });
    },
  },
  components: {
    Dialog,
    SupplierFile,
    TextAreaInput,
    ImageTemplate,
    Loading,
    Chip,
  },
  mounted() {
    this.getFiles();
    // 	.$on("reload:asset", () => {
    // 		this.getFiles();
    // 	});EventBus
  },
};
</script>
<style scoped>
tr:nth-child(odd) {
  background-color: rgb(199 201 53 / 15%);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 14px !important;
}
</style>
